export const chinaCategories = [
  {
    id: 1,
    name: "Shoes",
    seller: "shoe",
    icon: "shoes",
    sub: [
      {
        id: "1001",
        name: "Men Shoes",
      },
      {
        id: "1002",
        name: "Men Boots",
      },
      {
        id: "1003",
        name: "Ladies Shoes",
      },
      {
        id: "1004",
        name: "Ladies Boot",
      },
      {
        id: "1005",
        name: "Ladies High Heels",
      },
      {
        id: "1006",
        name: "Formal Shoes For Gents",
      },
      {
        id: "1007",
        name: "Sandals",
      },
      {
        id: "1008",
        name: "Running/ Gym Shoes",
      },
      {
        id: "1009",
        name: "Casual Shoes",
      },
      {
        id: "1010",
        name: "Loafers For Women",
      },
      {
        id: "1011",
        name: "Sports Shoes",
      },
      {
        id: "1012",
        name: "Baby Shoes",
      },
      {
        id: "1013",
        name: "Low Top Shoes",
      },
      {
        id: "1014",
        name: "Rain Boots",
      },
      {
        id: "1015",
        name: "Men High Top Shoes",
      },
      {
        id: "1016",
        name: "Football/Soccer Shoes",
      },
      {
        id: "1017",
        name: "Slippers",
      },
    ],
  },
  {
    id: 4,
    name: "Bags",
    seller: "Bag",
    icon: "handbag",
    sub: [
      {
        id: "4000",
        name: "Ladies Purse",
      },
      {
        id: "4001",
        name: "Ladies Bags",
      },
      {
        id: "4002",
        name: "Briefcases",
        search: "Handbag Briefcase ",
      },
      {
        id: "4003",
        name: "Money Clip",
      },
      {
        id: "4004",
        name: "Men Wallets",
      },
      {
        id: "4005",
        name: "Backpacks",
      },
      {
        id: "4006",
        name: "Women Wallets",
        search: "Female Leather Wallet",
      },
      {
        id: "4007",
        name: "Travel Bagpacks",
      },
      {
        id: "4008",
        name: "Id Cases",
      },
      {
        id: "4009",
        name: "Laptop Bags",
      },
      {
        id: "4010",
        name: "Suitcase",
      },
      {
        id: "4011",
        name: "Waist Bag",
      },
      {
        id: "4012",
        name: "Hand Bag",
      },
      {
        id: "4013",
        name: "Gift Box",
      },
      {
        id: "4014",
        name: "Baseball Caps",
      },
    ],
  },
  {
    id: 445,
    name: "Jewelry",
    seller: "jewelry",
    icon: "necklace",
    sub: [
      {
        id: "17000",
        name: "Men Bracelets",
        search: "Bracelets For Men",
      },
      {
        id: "17001",
        name: "Men Earrings",
        search: "",
      },
      {
        id: "17002",
        name: "Women Earrings",
        search: "Earrings For Women",
      },
      {
        id: "17003",
        name: "Jewellery Cases",
        search: "",
      },
      {
        id: "17004",
        name: "Women Necklaces",
        search: "Necklaces",
      },
      {
        id: "17005",
        name: "Bridal",
      },
      {
        id: "17006",
        name: "Crown For Women",
        search: "",
      },
      {
        id: "17007",
        name: "Bride Necklace",
        search: "",
      },
      {
        id: "17008",
        name: "Pendant",
        search: "",
      },
      {
        id: "17009",
        name: "Bangle",
        search: "",
      },
      {
        id: "17010",
        name: "Ring",
        search: "",
      },
      {
        id: "17011",
        name: "Anklet",
      },
      {
        id: "17012",
        name: "Womwn Tiara",
      },
      {
        id: "17013",
        name: "Belly Chain",
        search: "",
      },
      {
        id: "17014",
        name: "Jewellery Box",
        search: "",
      },
      {
        id: "17015",
        name: "Pin Brooch",
        search: "",
      },
      {
        id: "17016",
        name: "Fashion Jewelry",
      },
    ],
  },
  {
    id: 8,
    name: "Beauty and Personal Care",
    seller: "makeup",
    icon: "beauty_product",
    sub: [
      {
        id: "8000",
        name: "Eyeshadow",
        search: "",
      },
      {
        id: "8001",
        name: "Eyeliner",
        search: "",
      },
      {
        id: "8002",
        name: "Face Powder",
        search: "Face Cream",
      },
      {
        id: "8003",
        name: "Makeup Remover",
        search: " Oil Free Makeup Remover",
      },
      {
        id: "8004",
        name: "Eye Brushes",
        search: "Eye Shadow Brushes",
      },
      {
        id: "8005",
        name: "Hair Removal",
        search: "Hair Removal For Women",
      },
      {
        id: "8006",
        name: "Mirrors",
        search: "",
      },
      {
        id: "8007",
        name: "Teeth Whitening",
        search: "",
      },
      {
        id: "8008",
        name: "Nail Polish",
        search: "",
      },
      {
        id: "8009",
        name: "Lip Gloss",
        search: "",
      },
      {
        id: "8010",
        name: "Lip Liner",
        search: "",
      },
      {
        id: "8011",
        name: "Lip Sticks",
        search: "",
      },
      {
        id: "8012",
        name: "Anti Hair Thinning & Loss",
        search: "",
      },
      {
        id: "8013",
        name: "Hairspray & Styling",
        search: "",
      },
      {
        id: "8014",
        name: "Hair Dryers",
        search: "",
      },
      {
        id: "8015",
        name: "Hair Dye",
        search: "",
      },
      {
        id: "8016",
        name: "Hair Massage Oil",
        search: "",
      },
      {
        id: "8017",
        name: "Irons & Stylers",
        search: "Hair Care",
      },
      {
        id: "8018",
        name: "Bath & Shower",
        search: "",
      },
      {
        id: "8019",
        name: "Candles & Scents",
        search: "",
      },
      {
        id: "8020",
        name: "Lotions & Oil",
        search: "",
      },
      {
        id: "8021",
        name: "Perfume",
        search: "",
      },
      {
        id: "8022",
        name: "Perfume Sets",
        search: "",
      },
      {
        id: "8023",
        name: "Deodorant",
        search: "Body Spray",
      },
      {
        id: "8024",
        name: "Soaps",
        search: "",
      },
      {
        id: "8025",
        name: "Body Wash",
        search: "Beauty Soap",
      },
      {
        id: "8026",
        name: "Hair Conditioner",
        search: "",
      },
      {
        id: "8027",
        name: "Toner",
        search: "",
      },
      {
        id: "8028",
        name: "Facial Masks",
        search: "",
      },
      {
        id: "8029",
        name: "Face Moisturizer",
        search: "",
      },
      {
        id: "8030",
        name: "Eye Treatment ",
        search: "",
      },
      {
        id: "8031",
        name: "Face Serum",
        search: "",
      },
      {
        id: "8032",
        name: "Body Sunscreen",
        search: "",
      },
      {
        id: "8033",
        name: "Face Sunscreen",
        search: "",
      },
    ],
  },
  {
    id: 2,
    name: "Men’s Clothing",
    seller: "men",
    icon: "mens_clothing",
    sub: [
      {
        id: "2001",
        name: "Casual Pants For Men",
        search: "Pants Casual For Men",
      },
      {
        id: "2002",
        name: "Mens Jeans Pant",
        search: "",
      },
      {
        id: "2003",
        name: "Men Suit",
        search: "",
      },
      {
        id: "2004",
        name: "Leather Jackets For Men",
        search: "",
      },
      {
        id: "2005",
        name: "Winter Pajamas",
        search: "",
      },
      {
        id: "2006",
        name: "Shirts",
        search: "",
      },
      {
        id: "2007",
        name: "Athletic Shorts",
        search: "",
      },
      {
        id: "2008",
        name: "Tie Clips",
        search: "",
      },
      {
        id: "2009",
        name: "Mens Sweater",
        search: "",
      },
      {
        id: "2010",
        name: "Boxers",
        search: "Mens Underwear Boxer",
      },
      {
        id: "2011",
        name: "Mens Hat",
        search: "",
      },
      {
        id: "2012",
        name: "Leather Gloves",
        search: "",
      },
      {
        id: "2013",
        name: "Men Sweatpants",
        search: "Running Fitness Casual Trouser",
      },
      {
        id: "2014",
        name: "Socks",
        search: "",
      },
      {
        id: "2015",
        name: "Tie",
        search: "Tie Formal",
      },
      {
        id: "2016",
        name: "Bow Ties",
        search: "",
      },
      {
        id: "2017",
        name: "Gloves",
        search: "",
      },
    ],
  },
  {
    id: 3,
    name: "Women's Clothing",
    seller: "women",
    icon: "clothing",
    sub: [
      {
        id: "3000",
        name: "Hoodies & Sweatshirts",
      },
      {
        id: "3001",
        name: "Women Sets",
        search: "",
      },
      {
        id: "3002",
        name: "Womens Suits Blazers",
        search: "",
      },
      {
        id: "3003",
        name: "Bodysuits",
        search: "Womens Bodysuits",
      },
      {
        id: "3004",
        name: "Womens Coats Jackets",
        search: "",
      },
      {
        id: "3005",
        name: "Swimwear",
        search: "",
      },
      {
        id: "3006",
        name: "Bikini Sets",
        search: "",
      },
      {
        id: "3007",
        name: "Skirts",
        search: "",
      },
      {
        id: "3008",
        name: "Womens Jeans",
        search: "",
      },
      {
        id: "3009",
        name: "Pants & Capris",
        search: "Womens Pants Capris",
      },
      {
        id: "3010",
        name: "Event Dress",
        search: "",
      },
      {
        id: "3011",
        name: "Wedding Dresses",
        search: "",
      },
      {
        id: "3012",
        name: "Prom Dresses",
        search: "",
      },
      {
        id: "3013",
        name: "Evening Dresses",
        search: "",
      },
      {
        id: "3014",
        name: "Cosplay Costumes",
        search: "",
      },
      {
        id: "3015",
        name: "Bra",
        search: "Bra",
      },
      {
        id: "3016",
        name: "Panties",
        search: "Female Panties",
      },
      {
        id: "3017",
        name: "Women Socks",
        search: "WOMEN S SOCKS",
      },
      {
        id: "3018",
        name: "Bodysuit",
        search: "Bodysuit",
      },
      {
        id: "3019",
        name: "Bucket Hats",
        search: "Womens Bucket Hats",
      },
      {
        id: "3020",
        name: "Belts",
        search: "Female Belt",
      },
      {
        id: "3021",
        name: "Beach Style",
        search: "Womens Beach Fashion",
      },
    ],
  },
  {
    id: 5,
    name: "Baby Items",
    seller: "child",
    icon: "child-shoe",
    sub: [
      {
        id: "5000",
        name: "Baby Cleaning",
        search: "",
      },
      {
        id: "5001",
        name: "Baby Bottles",
        search: "Baby Feed Baby Bottle ",
      },
      {
        id: "5002",
        name: "Changing Mats & Cloth Diapers",
        search: "Baby Diaper Changing Pad",
      },
      {
        id: "5003",
        name: "Child Furniture",
        search: "",
      },
      {
        id: "5004",
        name: "Diapers",
        search: "",
      },
      {
        id: "5005",
        name: "Potty & Washing",
        search: "",
      },
      {
        id: "5006",
        name: "Baby Pools & Water Toys",
        search: "",
      },
      {
        id: "5007",
        name: "Remote Control Toys",
        search: "",
      },
      {
        id: "5008",
        name: "Baby Gift Sets",
        search: "",
      },
      {
        id: "5009",
        name: "Children S Formal Dress",
        search: "",
      },
      {
        id: "5010",
        name: "Children'S Socks",
        search: "",
      },
      {
        id: "5011",
        name: "Children S Swim Suit",
        search: "",
      },
      {
        id: "5012",
        name: "Children Under Garments",
      },
      {
        id: "5013",
        name: "Chinese Outfits",
        search: "",
      },
      {
        id: "5014",
        name: "Cotton Jackets",
        search: "CHILD JACKETS",
      },
      {
        id: "5015",
        name: "Baby Skirts Dresses",
        search: "",
      },
      {
        id: "5016",
        name: "Baby Sweaters",
        search: "",
      },
      {
        id: "5017",
        name: "Baby Shoes",
        search: "",
      },
      {
        id: "5018",
        name: "Baby Sports Shoes",
        search: "",
      },
      {
        id: "5019",
        name: "Baby Snacks",
        search: "",
      },
      {
        id: "5020",
        name: "Infant Formula",
        search: "",
      },
      {
        id: "5021",
        name: "Infant Milk Powder",
        search: "Milk Powder",
      },
      {
        id: "5022",
        name: "Maternity Supplies",
        search: "",
      },
      {
        id: "5023",
        name: "Stretch Mark Removal",
        search: "",
      },
    ],
  },
  {
    id: 18,
    name: "Eyewear",
    seller: "eye",
    icon: "sunglass",
    sub: [
      {
        id: "1801",
        name: "Sunglasses",
        search: "",
      },
      {
        id: "1802",
        name: "Ladies Sunglass",
        search: "",
      },
      {
        id: "1803",
        name: "Mens Sunglass",
        search: "",
      },
      {
        id: "1804",
        name: "Baby Sunglass",
        search: "",
      },
      {
        id: "1805",
        name: "Party Sunglass",
        search: "",
      },
      {
        id: "1806",
        name: "Goggles",
        search: "",
      },
      {
        id: "1807",
        name: "Reading Glasses",
        search: "",
      },
    ],
  },
  {
    id: 6,
    name: "Office & School Supplies",
    seller: "kid",
    icon: "kid",
    sub: [
      {
        id: "6000",
        name: "Water Purification Equipment",
        search: "",
      },
      {
        id: "6001",
        name: "Ceiling Fans",
        search: "",
      },
      {
        id: "6002",
        name: "Electric Blankets",
        search: "",
      },
      {
        id: "6003",
        name: "Fan",
        search: "",
      },
      {
        id: "6004",
        name: "Heaters",
        search: "Fan Heater",
      },
      {
        id: "6005",
        name: "Portable Air Conditioners",
        search: "",
      },
      {
        id: "6006",
        name: "Home Automation",
        search: "Smart Home Control",
      },
      {
        id: "6007",
        name: "Led Screens",
        search: "",
      },
      {
        id: "6008",
        name: "Monitoring Equipment",
        search: "",
      },
      {
        id: "6009",
        name: "Security Inspection Device",
        search: "",
      },
      {
        id: "6010",
        name: "Solar Panels",
        search: "",
      },
      {
        id: "6011",
        name: "Voltage Converters",
        search: "Electronic Voltage Converters",
      },
      {
        id: "6012",
        name: "Carpets & Rugs",
        search: "",
      },
      {
        id: "6013",
        name: "Curtains & Accessories",
        search: "",
      },
      {
        id: "6014",
        name: "Dust Protection",
        search: "",
      },
      {
        id: "6015",
        name: "Pillow",
        search: "",
      },
      {
        id: "6016",
        name: "Seat Cushions",
        search: "",
      },
      {
        id: "6017",
        name: "Decorative Umbrella",
        search: "",
      },
      {
        id: "6018",
        name: "Calculators",
        search: "",
      },
      {
        id: "6019",
        name: "Compasses",
        search: "",
      },
      {
        id: "6020",
        name: "Digital Pen Translators",
        search: "",
      },
      {
        id: "6021",
        name: "Electronic Dictionaries",
        search: "",
      },
      {
        id: "6022",
        name: "Kindles & E-Readers",
        search: "",
      },
      {
        id: "6023",
        name: "Lab Equipment",
        search: "",
      },
      {
        id: "6024",
        name: "Learning Tablets",
        search: "",
      },
      {
        id: "6025",
        name: "Pencil Erasers",
        search: "",
      },
      {
        id: "6026",
        name: "Pencil Grips",
        search: "",
      },
      {
        id: "6027",
        name: "Planners",
        search: "",
      },
      {
        id: "6028",
        name: "Tape",
        search: "",
      },
      {
        id: "6029",
        name: "Whiteboards",
        search: "",
      },
      {
        id: "6030",
        name: "Whiteboard Magnets",
        search: "",
      },
      {
        id: "6031",
        name: "Hole Punches",
        search: "Punching Machine Hole",
      },
      {
        id: "6032",
        name: "Scissors & X-Acto Knives",
        search: "",
      },
      {
        id: "6033",
        name: "Projectors",
        search: "",
      },
      {
        id: "6034",
        name: "Diaries & Planners",
        search: "",
      },
      {
        id: "6035",
        name: "Wrapping Paper",
        search: "",
      },
      {
        id: "6036",
        name: "Binding Accessories",
      },
      {
        id: "6037",
        name: "Laminating Paper",
      },
    ],
  },
  {
    id: 669,
    name: "Seasonal Products",
    seller: "bridal",
    icon: "bridal",
    sub: [
      {
        id: "18000",
        name: "Summer",
        search: "",
      },
      {
        id: "18001",
        name: "Summer Dress",
        search: "",
      },
      {
        id: "18002",
        name: "Ice Cream Maker",
        search: "",
      },
      {
        id: "18003",
        name: "Juicer",
        search: "",
      },
      {
        id: "18004",
        name: "Neck Fan",
        search: "",
      },
      {
        id: "18005",
        name: "Rainy Season",
        search: "",
      },
      {
        id: "18006",
        name: "Rain Coat",
        search: "",
      },
      {
        id: "18007",
        name: "Magic Umbrella",
        search: "",
      },
      {
        id: "18008",
        name: "Mosquito Killing Racket",
        search: "Mosquito",
      },
      {
        id: "18009",
        name: "Bike Raincoat",
        search: "",
      },
      {
        id: "18010",
        name: "Waterproof Backpack",
        search: "",
      },
      {
        id: "18011",
        name: "Autumn",
        search: "",
      },
      {
        id: "18012",
        name: "Winter",
        search: "",
      },
      {
        id: "18013",
        name: "Winter Dresses",
        search: "",
      },
      {
        id: "18014",
        name: "Badminton",
        search: "",
      },
    ],
  },
  {
    id: 6235,
    name: "Phone accessories",
    seller: "mobile",
    icon: "mobile",
    sub: [
      {
        id: "19000",
        name: "Xiaomi",
        search: "",
      },
      {
        id: "19001",
        name: "Apple",
        search: "",
      },
      {
        id: "19002",
        name: "Huwaei",
        search: "",
      },
      {
        id: "19003",
        name: "Realme",
        search: "",
      },
      {
        id: "19004",
        name: "One Plus",
        search: "",
      },
      {
        id: "19005",
        name: "Samsung",
        search: "",
      },
      {
        id: "19006",
        name: "Google",
        search: "",
      },
      {
        id: "19007",
        name: "Vivo ",
        search: "Vivo Smartphone",
      },
      {
        id: "19008",
        name: "Nokia",
        search: "",
      },
      {
        id: "19009",
        name: "Lg",
        search: "",
      },
      {
        id: "19010",
        name: "Tecno",
        search: "",
      },
      {
        id: "19011",
        name: "Htc",
        search: "",
      },
      {
        id: "19012",
        name: "Motorola",
        search: "",
      },
      {
        id: "19013",
        name: "Asus",
        search: "",
      },
    ],
  },
  {
    id: 7,
    name: "Sports & Fitness",
    seller: "sport",
    icon: "sport",
    sub: [
      {
        id: "7000",
        name: "Snowboarding",
        search: "",
      },
      {
        id: "7001",
        name: "Jet Skis",
        search: "",
      },
      {
        id: "7002",
        name: "Swimming Gear",
        search: "",
      },
      {
        id: "7003",
        name: "Fishing Rods",
        search: "",
      },
      {
        id: "7004",
        name: "Grills & Bbq Equipment",
        search: "Bbq Machine",
      },
      {
        id: "7005",
        name: "Luggage Locks",
        search: "",
      },
      {
        id: "7006",
        name: "Military Apparel",
        search: "",
      },
      {
        id: "7007",
        name: "Yoga Accessories",
        search: "",
      },
      {
        id: "7008",
        name: "Tents & Canopies",
        search: "",
      },
      {
        id: "7009",
        name: "Fitness Clothes",
        search: "",
      },
    ],
  },

  {
    id: 9,
    name: "Entertainment Items",
    seller: "electronic",
    icon: "electronics",
    sub: [
      {
        id: "9000",
        name: "Gopro",
        search: "4K Action Camera",
      },
      {
        id: "9001",
        name: "Cameras",
        search: "",
      },
      {
        id: "9002",
        name: "Travel",
        search: "Travelling",
      },
      {
        id: "9003",
        name: "Travel Bags",
        search: "",
      },
      {
        id: "9004",
        name: "Travel Luggage",
        search: "Baggage Box",
      },
      {
        id: "9005",
        name: "Travel Pillows",
        search: "",
      },
      {
        id: "9006",
        name: "4K Ultra Hd Tvs",
        search: "",
      },
      {
        id: "9007",
        name: "Beanbags & Sofa Chairs",
        search: "",
      },
      {
        id: "9008",
        name: "Flat Tvs",
        search: "",
      },
      {
        id: "9009",
        name: "Movie Poster",
        search: "",
      },
      {
        id: "9010",
        name: "Popcorn Makers",
        search: "",
      },
      {
        id: "9011",
        name: "Vaping Gear",
        search: "Electronic Ciggarate",
      },
      {
        id: "9012",
        name: "Video Games",
        search: "Games Controller",
      },
      {
        id: "9013",
        name: "Virtual Reality",
        search: "",
      },
      {
        id: "9014",
        name: "Nintendo Wii",
        search: "",
      },
      {
        id: "9015",
        name: "Bluetooth Speakers",
        search: "",
      },
      {
        id: "9016",
        name: "Drawing Tablet",
        search: "",
      },
      {
        id: "9017",
        name: "Headphones",
        search: "",
      },
      {
        id: "9018",
        name: "Ipad",
        search: "",
      },
    ],
  },
  {
    id: 10,
    name: "Watches",
    seller: "watch",
    icon: "watch",
    sub: [
      {
        id: "10000",
        name: "Womens Watch",
        search: "",
      },
      {
        id: "10001",
        name: "Male Watches",
        search: "",
      },
      {
        id: "10002",
        name: "Sports Watches",
        search: "Female Watch",
      },
      {
        id: "10003",
        name: "Watch",
        search: "",
      },
      {
        id: "10004",
        name: "Smart Watches",
        search: "",
      },
      {
        id: "10005",
        name: "Branded Watches",
        search: "",
      },
      {
        id: "10006",
        name: "Gshock ",
      },
      {
        id: "10007",
        name: "Apple Watch",
        search: "",
      },
      {
        id: "10008",
        name: "Huawei Watches",
        search: "",
      },
      {
        id: "10009",
        name: "Smart Fit Band",
        search: "",
      },
      {
        id: "10010",
        name: "Amazefit Watches",
        search: "",
      },
    ],
  },
  {
    id: 11,
    name: "Automobile Items",
    seller: "automobile",
    icon: "automobile",
    sub: [
      {
        id: "11000",
        name: "Auto Oils & Fluids",
        search: "Store Abb B2B 2207674923374E092A",
      },
      {
        id: "11001",
        name: "Automobile",
        search: "",
      },
      {
        id: "11002",
        name: "Interior Accessories",
        search: "Car Interior",
      },
      {
        id: "11003",
        name: "Exterior Accessories",
        search: "",
      },
      {
        id: "11004",
        name: "Covers",
        search: "Car Dust Cover",
      },
      {
        id: "11005",
        name: "Exterior Vehicle Care",
        search: "",
      },
      {
        id: "11006",
        name: "Interior Vehicle Care",
        search: "Car Interior Vehicle Care",
      },
      {
        id: "11007",
        name: "Car Electronics Accessories",
        search: "",
      },
      {
        id: "11008",
        name: "Car Audio Accesories",
        search: "",
      },
      {
        id: "11009",
        name: "Motorcycle",
        search: "",
      },
      {
        id: "11010",
        name: "Motorcycle Accessories",
        search: "",
      },
      {
        id: "11011",
        name: "Motorcycle Riding Accesories",
        search: "",
      },
      {
        id: "11012",
        name: "Car Kit",
        search: "",
      },
    ],
  },
  {
    id: 12,
    name: "Groceries and Pets",
    seller: "seed",
    icon: "grocery",
    sub: [
      {
        id: "12000",
        name: "Breakfast, Choco & Snacks",
        search: "",
      },
      {
        id: "12001",
        name: "Beverages",
        search: "",
      },
      {
        id: "12002",
        name: "Food Staples",
        search: "Staple Food",
      },
      {
        id: "12003",
        name: "Cooking Ingredients",
        search: "",
      },
      {
        id: "12004",
        name: "Laundry & Household",
        search: "",
      },
      {
        id: "12005",
        name: "Cat",
        search: "",
      },
      {
        id: "12006",
        name: "Cat Bag",
        search: "",
      },
      {
        id: "12007",
        name: "Dog",
        search: "",
      },
      {
        id: "12008",
        name: "Fish",
        search: "",
      },
      {
        id: "12009",
        name: "Bird",
        search: "",
      },
      {
        id: "12010",
        name: "Small Pet",
        search: "",
      },
    ],
  },
  {
    id: 13,
    name: "Outdoor & Travelling",
    seller: "travel",
    icon: "travel",
    sub: [
      {
        id: "13000",
        name: "Fishing Reels",
        search: "",
      },
      {
        id: "13001",
        name: "Fishing",
        search: "",
      },
      {
        id: "13002",
        name: "Fishing Lines",
        search: "",
      },
      {
        id: "13003",
        name: "Fishing Rods",
        search: "",
      },
      {
        id: "13004",
        name: "Fishing Tackle Boxes",
        search: "",
      },
      {
        id: "13005",
        name: "Sportswear",
        search: "",
      },
      {
        id: "13006",
        name: "Sports Bag",
        search: "",
      },
      {
        id: "13007",
        name: "Sport Accessories",
        search: "",
      },
      {
        id: "13008",
        name: "Camping & Hiking",
        search: "",
      },
      {
        id: "13009",
        name: "Hunting",
        search: "",
      },
      {
        id: "13010",
        name: "Fitness & Bodybuilding",
        search: "",
      },
      {
        id: "13011",
        name: "Skiing & Snowboarding",
        search: "",
      },
      {
        id: "13012",
        name: "Musical Instrument",
        search: "",
      },
    ],
  },
  {
    id: 14,
    name: "Electronics & Gadgets",
    seller: "Phone",
    icon: "electronics",
    sub: [
      {
        id: "14000",
        name: "Accessories & Supplies",
        search: "Electric Supplies",
      },
      {
        id: "14001",
        name: "Electronics",
        search: "",
      },
      {
        id: "14002",
        name: "Camera & Photo",
        search: "",
      },
      {
        id: "14003",
        name: "Car & Vehicle Electronics",
        search: "",
      },
      {
        id: "14004",
        name: "Cell Phones & Accessories",
        search: "",
      },
      {
        id: "14005",
        name: "Computers & Accessories",
        search: "",
      },
      {
        id: "14006",
        name: "Gps & Navigation",
        search: "",
      },
      {
        id: "14007",
        name: "Home Audio",
        search: "",
      },
      {
        id: "14008",
        name: "Office Electronics",
        search: "Office Electronic Supplies",
      },
      {
        id: "14009",
        name: "Portable Audio & Video",
        search: "",
      },
      {
        id: "14010",
        name: "Security & Surveillance",
      },
      {
        id: "14011",
        name: "Service Plans",
        search: "",
      },
      {
        id: "14012",
        name: "Television & Video",
        search: "",
      },
      {
        id: "14013",
        name: "Video Game Consoles & Accessories",
        search: "",
      },
      {
        id: "14014",
        name: "Video Projectors",
        search: "",
      },
      {
        id: "14015",
        name: "Wearable Smart Device",
      },
      {
        id: "14016",
        name: "Ebook Readers & Accessories",
        search: "",
      },
    ],
  },
  {
    id: 15,
    name: "Home And Kitchen",
    seller: "kitchen",
    icon: "home_improvements",
    sub: [
      {
        id: "15000",
        name: "Medical Supplies & Equipment",
        search: "",
      },
      {
        id: "15001",
        name: "Household Supplies",
        search: "",
      },
      {
        id: "15002",
        name: "Oral Care",
        search: "",
      },
      {
        id: "15003",
        name: "Personal Care",
        search: "",
      },
      {
        id: "15004",
        name: "Sexual Wellness",
        search: "Sexual ",
      },
      {
        id: "15005",
        name: "Health Nutrition",
        search: "",
      },
      {
        id: "15006",
        name: "Stationery & Gift Wrapping Supplies",
        search: "Gift Wrapping Supplies",
      },
      {
        id: "15007",
        name: "Vision Care",
        search: "Eye Care",
      },
      {
        id: "15008",
        name: "Vitamins & Dietary Supplements",
        search: "",
      },
      {
        id: "15009",
        name: "Wellness & Relaxation",
        search: "",
      },
      {
        id: "15010",
        name: "Kids' Home Store",
        search: "",
      },
      {
        id: "15011",
        name: "Kitchen ",
        search: "",
      },
      {
        id: "15012",
        name: "Dining",
        search: "",
      },
      {
        id: "15013",
        name: "Bedding",
        search: "",
      },
      {
        id: "15014",
        name: "Bath",
        search: "",
      },
      {
        id: "15015",
        name: "Furniture",
        search: "",
      },
      {
        id: "15016",
        name: "Wall Art",
        search: "",
      },
    ],
  },
  {
    id: 16,
    name: "Tools & Home Improvement",
    seller: "tools",
    icon: "tools",
    sub: [
      {
        id: "16000",
        name: "Event & Party Supplies",
        search: "",
      },
      {
        id: "16001",
        name: "Heating, Cooling & Air Quality",
        search: "",
      },
      {
        id: "16002",
        name: "Electric Irons & Steamers",
        search: "Irons For Clothes",
      },
      {
        id: "16003",
        name: "Vacuum Cleaner",
        search: "",
      },
      {
        id: "16004",
        name: "Storage Organizer",
        search: "",
      },
      {
        id: "16005",
        name: "Cleaning Supplies",
        search: "",
      },
      {
        id: "16006",
        name: "Smart Home Appliances",
        search: "",
      },
      {
        id: "16007",
        name: "Building Supplies",
        search: "",
      },
      {
        id: "16008",
        name: "Electrical",
        search: "",
      },
      {
        id: "16009",
        name: "Kitchen & Bath Fixtures",
        search: "",
      },
      {
        id: "16010",
        name: "Light Bulbs",
        search: "",
      },
      {
        id: "16011",
        name: "Lighting & Ceiling Fans",
        search: "",
      },
      {
        id: "16012",
        name: "Measuring & Layout Tool",
        search: "",
      },
      {
        id: "16013",
        name: "Painting Supplies & Wall Treatments",
        search: "Wall Paint",
      },
      {
        id: "16014",
        name: "Power & Hand Tools",
        search: "",
      },
      {
        id: "16015",
        name: "Safety & Security",
        search: "Safety Security Accesories",
      },
      {
        id: "16016",
        name: "Storage & Home Organization",
        search: "",
      },
      {
        id: "16017",
        name: "Welding & Soldering",
        search: "",
      },
    ],
  },
];
