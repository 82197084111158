export const pakistanCategories = [
  {
    id: 1,
    name: "Men's Collection",
    icon: "mens_clothing",
    sub: [
      // {
      //   id: "1000",
      //   name: "Men's Kurta",
      // },
      // {
      //   id: "1001",
      //   name: "Men's Casual suits",
      // },
      // {
      //   id: "1002",
      //   name: "Men's Formal Suits",
      // },
      // {
      //   id: "1003",
      //   name: "Men's Kabli",
      // },
      // {
      //   id: "1004",
      //   name: "Men's Panjabi",
      // },
      // {
      //   id: "1005",
      //   name: "Men's Waistcoat",
      // },
      // {
      //   id: "1006",
      //   name: "Men's Shalwar",
      // },
      // {
      //   id: "1007",
      //   name: "Men's Shirt",
      // },
      // {
      //   id: "1008",
      //   name: "Men's Polo Shirt",
      // },
      // {
      //   id: "1009",
      //   name: "Men's T- Shirt",
      // },
      // {
      //   id: "1010",
      //   name: "Men's Pant",
      // },
      // {
      //   id: "1011",
      //   name: "Men's Unstitched Fabric",
      // },
    ],
  },
  {
    id: 2,
    name: "Women's Collection",
    icon: "clothing",
    sub: [
      // {
      //   id: "2000",
      //   name: "Cotton Collection",
      // },
      // {
      //   id: "2001",
      //   name: "Embroidery Collection",
      // },
      // {
      //   id: "2002",
      //   name: "Swiss Lawn Collection",
      // },
      // {
      //   id: "2003",
      //   name: "Net Collection",
      // },
      // {
      //   id: "2004",
      //   name: "Luxury Collection ",
      // },
      // {
      //   id: "2005",
      //   name: "Semi- Stitched collection",
      // },
      // {
      //   id: "2006",
      //   name: "Unstitched Collection",
      // },
    ],
  },
  {
    id: 3,
    name: "Wedding Exclusive",
    icon: "weeding",
    sub: [
      // {
      //   id: "3000",
      //   name: "Men's Sherwani",
      // },
      // {
      //   id: "3002",
      //   name: "Men's Kameez Shalwar",
      // },
      // {
      //   id: "3003",
      //   name: "Men's Exclusive Suits",
      // },
      // {
      //   id: "3004",
      //   name: "Bridal Lehenga",
      // },
      // {
      //   id: "3005",
      //   name: "Bridal Saare",
      // },
      // {
      //   id: "3006",
      //   name: "Bridal Gharara Collection",
      // },
      // {
      //   id: "3007",
      //   name: "Bridal Sharara Collection",
      // },
      // {
      //   id: "3008",
      //   name: "Bridal Anarkalis",
      // },
      // {
      //   id: "3009",
      //   name: "Bridal Dupatta",
      // },
      // {
      //   id: "3010",
      //   name: "Bridal Gown",
      // },
    ],
  },
  {
    id: 4,
    name: "Bridal Exclusive",
    icon: "bridal",
    sub: [
      // {
      //   id: "4000",
      //   name: "Men's Kameez Shalwar",
      // },
      // {
      //   id: "4001",
      //   name: "Men's Exclusive Suits",
      // },
      // {
      //   id: "4002",
      //   name: "Bridal Lehenga",
      // },
      // {
      //   id: "4003",
      //   name: "Bridal Saare",
      // },
      // {
      //   id: "4004",
      //   name: "Bridal Gharara Collection",
      // },
      // {
      //   id: "4005",
      //   name: "Bridal Sharara Collection",
      // },
      // {
      //   id: "4006",
      //   name: "Bridal Anarkalis",
      // },
      // {
      //   id: "4007",
      //   name: "Bridal Dupatta",
      // },
      // {
      //   id: "4008",
      //   name: "Bridal Gown",
      // },
    ],
  },
  {
    id: 5,
    name: "Jewelry Collection",
    icon: "necklace",
    sub: [
      // {
      //   id: "5000",
      //   name: "Necklaces & Choker",
      // },
      // {
      //   id: "5001",
      //   name: "Bracelets",
      // },
      // {
      //   id: "5002",
      //   name: "Rings",
      // },
      // {
      //   id: "5003",
      //   name: "Earrings",
      // },
      // {
      //   id: "5004",
      //   name: "Pedants",
      // },
      // {
      //   id: "5005",
      //   name: "Bun Pins",
      // },
      // {
      //   id: "5006",
      //   name: "Gold Plated Jewelry",
      // },
      // {
      //   id: "5007",
      //   name: "Imitation Jewelry",
      // },
      // {
      //   id: "5008",
      //   name: "Bangles",
      // },
      // {
      //   id: "5009",
      //   name: "Bridal Exclusive Jewelry",
      // },
    ],
  },
  {
    id: 6,
    name: "Beauty & Health",
    icon: "beauty_product",
    sub: [
      // {
      //   id: "6000",
      //   name: "Makeup",
      // },
      // {
      //   id: "6001",
      //   name: "Skin Care",
      // },
      // {
      //   id: "6002",
      //   name: "Hair Care",
      // },
      // {
      //   id: "6003",
      //   name: "Bath & Body ",
      // },
      // {
      //   id: "6004",
      //   name: "Personal Care",
      // },
    ],
  },
  {
    id: 7,
    name: "Kids Collection",
    icon: "kid",
    sub: [
      // {
      //   id: "7000",
      //   name: "Boys Clothing",
      // },
      // {
      //   id: "7001",
      //   name: "Girls Clothing",
      // },
      // {
      //   id: "7002",
      //   name: "Kids Rompers",
      // },
      // {
      //   id: "7003",
      //   name: "Kids Nightwear",
      // },
      // {
      //   id: "7004",
      //   name: "Kids Abaya",
      // },
      // {
      //   id: "7005",
      //   name: "Kids Scarfs",
      // },
      // {
      //   id: "7006",
      //   name: "Kids Accessories",
      // },
    ],
  },
  {
    id: 8,
    name: "Bags & Wallets",
    icon: "handbag",
    sub: [
      // {
      //   id: "8000",
      //   name: "Handbags",
      // },
      // {
      //   id: "8001",
      //   name: "Cross body Bags",
      // },
      // {
      //   id: "8002",
      //   name: "Clutches",
      // },
      // {
      //   id: "8003",
      //   name: "Wallets",
      // },
      // {
      //   id: "8004",
      //   name: "Shoulder Bags",
      // },
      // {
      //   id: "8005",
      //   name: "Laptop Bags",
      // },
      // {
      //   id: "8006",
      //   name: "Bag pack",
      // },
      // {
      //   id: "8007",
      //   name: "School Bags",
      // },
      // {
      //   id: "8008",
      //   name: "Hand Purse",
      // },
    ],
  },
  {
    id: 9,
    name: "Footwear",
    icon: "shoes",
    sub: [
      // {
      //   id: "9000",
      //   name: "Women's Slippers & Flip Flops",
      // },
      // {
      //   id: "9001",
      //   name: "Sandals",
      // },
      // {
      //   id: "9002",
      //   name: "Flat Shoes",
      // },
      // {
      //   id: "9003",
      //   name: "Women's khussay",
      // },
      // {
      //   id: "9004",
      //   name: "Men's Shoes",
      // },
      // {
      //   id: "9005",
      //   name: "Men's Slippers & Flip Flops",
      // },
      // {
      //   id: "9006",
      //   name: "Women's Heels",
      // },
    ],
  },
  {
    id: 10,
    name: "Pakistani Perfumes",
    icon: "perfume",
    sub: [
      // {
      //   id: "10000",
      //   name: "Men's perfume",
      // },
      // {
      //   id: "10001",
      //   name: "Women's Perfume",
      // },
      // {
      //   id: "10002",
      //   name: "Unisex Perfume",
      // },
      // {
      //   id: "10003",
      //   name: "Men's Body Spray",
      // },
      // {
      //   id: "10004",
      //   name: "Women's Body Spray",
      // },
    ],
  },
];
