export const alibabaCategories = [
  {
    id: 1,
    name: "Men’s Outfit",
    icon: "mens_clothing",
    sub: [
      { id: "1000", name: "Formal / Casual/Jeans Pants for Men" },
      { id: "1001", name: "Leather Jackets for Men" },
      { id: "1002", name: "Jerseys for Men" },
      { id: "1003", name: "Swimming Trunks" },
      { id: "1004", name: "Tie Clips & Ties " },
      { id: "1005", name: "Leather Gloves" },
      { id: "1006", name: "Men Blazers" },
      { id: "1007", name: "Men's Belts" },
      { id: "1008", name: "Casual Sweaters for Men" },
      { id: "1009", name: "Beanies & Hats" },
      { id: "1010", name: "T-Shirts for Men" },
    ],
  },
  {
    id: 2,
    name: "Women’s Outfit",
    icon: "clothing",
    sub: [
      { id: "2000", name: "Women’s dresses" },
      { id: "2001", name: "Gown & party wear" },
      { id: "2002", name: "Top, Shorts, Skirts" },
      { id: "2003", name: "Pants, jeans, leggings" },
      { id: "2004", name: "T-shirts & Hoodies" },
      { id: "2005", name: "Jumpsuit" },
      { id: "2006", name: "Undergarments" },
      { id: "2007", name: "Women’s playsuit" },
      { id: "2008", name: "Women’s Swimwear " },
      { id: "2009", name: "Formal Dresses" },
      { id: "2010", name: "Winter clothing" },
      { id: "2011", name: "Hair accessories & head wear" },
    ],
  },
  {
    id: 3,
    name: "Jewelry",
    icon: "necklace",
    sub: [
      { id: "3000", name: "Earring" },
      { id: "3001", name: "Finger Ring" },
      { id: "3002", name: "Necklace & chokers" },
      { id: "3003", name: "Pendants" },
      { id: "3004", name: "Bracelets" },
      { id: "3005", name: "Bangles" },
      { id: "3006", name: "Bun pins" },
      { id: "3007", name: "Hair clips & Bands" },
      { id: "3008", name: "Nose pins" },
      { id: "3009", name: "Brooches" },
    ],
  },
  {
    id: 4,
    name: "Cosmetics Items",
    icon: "beauty_product",
    sub: [
      { id: "4000", name: "Facial mask" },
      { id: "4001", name: "Hair Oil" },
      { id: "4002", name: "Hair Mask" },
      { id: "4003", name: "Hair Gel" },
      { id: "4004", name: "Bath Gel" },
      { id: "4005", name: "Shampoo" },
      { id: "4006", name: "Body oil" },
      { id: "4007", name: "Eye Shadow" },
      { id: "4008", name: "Eyebrow Enhancers " },
      { id: "4009", name: "Eyeliner" },
      { id: "4010", name: "Eyebrow Pencil" },
      { id: "4011", name: "Lipstick" },
      { id: "4012", name: "Brushes" },
      { id: "4013", name: "Beauty blender" },
      { id: "4014", name: "Drawers" },
    ],
  },
  {
    id: 5,
    name: "Bags & Wallets",
    icon: "handbag",
    sub: [
      { id: "5000", name: "Hand Bags" },
      { id: "5001", name: "Cross body Bags" },
      { id: "5002", name: "Clutches" },
      { id: "5003", name: "Men’s & Women’s Wallet" },
      { id: "5004", name: "Shoulder Bags" },
      { id: "5005", name: "Laptop Bags" },
      { id: "5006", name: "Bag pack" },
      { id: "5007", name: "School Bags" },
      { id: "5008", name: "Hand Purse" },
      { id: "5009", name: "Makeup Bags" },
      { id: "5010", name: "Travel Bags" },
      { id: "5011", name: "Sports Bags" },
    ],
  },
  {
    id: 6,
    name: "Home & Kitchen Appliances",
    icon: "home_improvements",
    sub: [
      { id: "6000", name: "Blender, Juicer, Mixer" },
      { id: "6001", name: "Rice Cooker" },
      { id: "6002", name: "Kettles & Air-Pots" },
      { id: "6003", name: "Coffee Maker & Boilers" },
      { id: "6004", name: "Meat Grinder" },
      { id: "6005", name: "Microwaves" },
      { id: "6006", name: "Air Purifier" },
      { id: "6007", name: "Air Conditioner" },
      { id: "6008", name: "Desk Fan" },
      { id: "6009", name: "Air Cooler" },
      { id: "6010", name: "Wet & Dry Vacuum" },
      { id: "6011", name: "Floor Polishers" },
      { id: "6012", name: "Table-Top Sewing Machine" },
      { id: "6013", name: "Hand Held Sewing Machine" },
      { id: "6014", name: "Steamer" },
      { id: "6015", name: "Washing Machines" },
      { id: "6016", name: "Vacuum Cleaner Parts" },
      { id: "6017", name: "Washer - Dryer Parts & Accessories" },
      { id: "6018", name: "Lights & Electrical" },
      { id: "6019", name: "Kitchen Application" },
      { id: "6020", name: "Crockeries" },
      { id: "6021", name: "Spoons Forks & Others" },
      { id: "6022", name: "Cleaning Products" },
      { id: "6023", name: "Air Freshener" },
      { id: "6024", name: "Pest Control " },
    ],
  },
  {
    id: 7,
    name: "Baby Products",
    icon: "child-shoe",
    sub: [
      { id: "7000", name: "Baby Dress" },
      { id: "7001", name: "Baby Shoe" },
      { id: "7002", name: "Newborn Essentials" },
      { id: "7003", name: "Diapers & Wipes" },
      { id: "7004", name: "Feeders" },
      { id: "7005", name: "Baby Food" },
      { id: "7006", name: "Bath & Skincare" },
      { id: "7007", name: "Baby Accessories" },
      { id: "7008", name: "Baby Oral Care" },
      { id: "7009", name: "Others Accessories" },
    ],
  },
  {
    id: 8,
    name: "FOOD ITEMS",
    icon: "food",
    sub: [
      { id: "8000", name: "Diabetic Food" },
      { id: "8001", name: "Breakfast" },
      { id: "8002", name: "Beverages" },
      { id: "8003", name: "Chocolate" },
      { id: "8004", name: "Snacks" },
      { id: "8005", name: "Dairy" },
      { id: "8006", name: "Frozen & Canned" },
      { id: "8007", name: "Bread & Bakery" },
      { id: "8008", name: "Baking Needs" },
      { id: "8009", name: "Cooking Essential" },
    ],
  },
  {
    id: 9,
    name: "Gadgets & Accessories",
    icon: "laptop",
    sub: [
      { id: "9000", name: "Speaker" },
      { id: "9001", name: "Headset" },
      { id: "9002", name: "Charger & Adapter" },
      { id: "9003", name: "Wireless Charger" },
      { id: "9004", name: "Car Accessories" },
      { id: "9005", name: "Tripod/ Selfie Stick" },
      { id: "9006", name: "Router" },
      { id: "9007", name: "Hard Drive" },
      { id: "9008", name: "Wireless Headphones" },
      { id: "9009", name: "TWS (True Wireless)" },
    ],
  },
  {
    id: 10,
    name: "Office & Home Security",
    icon: "electronics",
    sub: [
      { id: "10000", name: "Smart Tracker" },
      { id: "10001", name: "Home Locker" },
      { id: "10002", name: "Personal Lockers" },
      { id: "10003", name: "Home alarm systems" },
      { id: "10004", name: "Security Cameras/ CC camera" },
      { id: "10005", name: "Biometric Attendance System" },
      { id: "10006", name: "Smart Door Locker" },
      { id: "10007", name: "Fire & Smoke Alarm" },
      { id: "10008", name: "Door/ Window Sensor" },
    ],
  },
  {
    id: 11,
    name: "Office & Home Décor",
    icon: "home_improvements",
    sub: [
      { id: "11000", name: "Interior Lighting" },
      { id: "11001", name: "Home Decorations: Clocks, Mirrors, Frames, Vases" },
      { id: "11002", name: "Floor Covering: Indoor flooring, Carpeting" },
      { id: "11003", name: "Paint Decorating" },
      { id: "11004", name: "Wood working" },
    ],
  },
  {
    id: 12,
    name: "Travelling & Outdoor",
    icon: "travel",
    sub: [
      { id: "12000", name: "Hiking Rope" },
      { id: "12001", name: "Tent" },
      { id: "12002", name: "Waterproof Bag" },
      { id: "12003", name: "Tent Rod" },
      { id: "12004", name: "Blowing Machine" },
      { id: "12005", name: "Solar Light for tent" },
      { id: "12006", name: "Portable Gas Stove" },
      { id: "12007", name: "Portable Bed" },
      { id: "12008", name: "Fishing Rod" },
      { id: "12009", name: "Hanger Lock " },
    ],
  },
];
